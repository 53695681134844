import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { HeaderUtil } from './headerUtil';
import { ExcepcionesUtil } from './excepcionesUtil';

@Injectable()
export class HttpRequestUtils {

    constructor(private _http: HttpClient, private _header: HeaderUtil, private _handleError: ExcepcionesUtil) { }

    getRequest(url: any): Observable<any> {
        const header = this._header.getHeader();
        return this._http.get<any>(url, header)
            .pipe(
                catchError(this._handleError.getHandleError)
            );
    }

    putRequest(url: any, objectRequest: any): Observable<any> {
        return this._http.put<any>(url, JSON.stringify(objectRequest), this._header.getHeader())
            .pipe(
                catchError(this._handleError.getHandleError)
            );
    }

    postRequest(url: any, objectRequest: any): Observable<any> {
        return this._http.post<any>(url, JSON.stringify(objectRequest), this._header.getHeader())
            .pipe(
                catchError(this._handleError.getHandleError)
            );
    }

    postRequestLogin(url: any, objectRequest: any): Observable<any> {
        return this._http.post<any>(url, JSON.stringify(objectRequest), this._header.getHeaderSimple())
            .pipe(
                catchError(this._handleError.getHandleError)
            );
    }

    deleteRequest(url: any): Observable<any> {
        return this._http.delete<any>(url, this._header.getHeader())
            .pipe(
                catchError(this._handleError.getHandleError)
            );
    }

}
