import { Injectable } from '@angular/core';

export interface BadgeItem {
    type: string;
    value: string;
}
export interface Saperator {
    name: string;
    type?: string;
}
export interface SubChildren {
    state: string;
    name: string;
    type?: string;
}
export interface ChildrenItems {
    state: string;
    name: string;
    type?: string;
    child?: SubChildren[];
}

export interface Menu {
    state: string;
    name: string;
    type: string;
    icon: string;
    badge?: BadgeItem[];
    saperator?: Saperator[];
    children?: ChildrenItems[];
}

const MENUITEMS = [
    {
        state: 'dashboard',
        name: 'Dashboard',
        type: 'link',
        icon: 'content_copy'
    },
    {
        state: 'caja',
        name: 'Caja',
        type: 'sub',
        icon: 'local_atm',
        children: [
            { state: 'abono-caja', name: 'Apertura y abonos', type: 'link' },
            { state: 'cierre-caja', name: 'Cierre de caja', type: 'link' }
        ]
    },
    {
        state: 'venta',
        name: 'Ventas',
        type: 'sub',
        icon: 'attach_money',
        children: [
            { state: 'venta-nueva', name: 'Nueva Venta', type: 'link' },
            { state: 'venta-nueva-2', name: 'Nueva Venta 2.0', type: 'link' },
            { state: 'ventas-recientes', name: 'Ventas Recientes', type: 'link' },
            { state: 'libro-ventas', name: 'Libro de Ventas', type: 'link' },
            { state: 'garantia', name: 'Garantía', type: 'link' }
        ]
    },
    {
        state: 'empleado',
        name: 'Empleados',
        type: 'link',
        icon: 'person'
    },
    {
        state: 'cliente',
        name: 'Cliente',
        type: 'link',
        icon: 'perm_contact_calendar'
    },
    {
        state: 'company',
        name: 'Convenio Empresas',
        type: 'link',
        icon: 'work'
    },
    {
        state: 'inventario',
        name: 'Inventario',
        type: 'sub',
        icon: 'description',
        children: [
            { state: 'marco', name: 'Marcos', type: 'link' },
            { state: 'accesorio', name: 'Accesorios', type: 'link' },
            { state: 'merma', name: 'Merma', type: 'link' }
        ]
    },
    {
        state: 'insumos',
        name: 'Insumos',
        type: 'link',
        icon: 'assignment'
    },
    {
        state: 'reportes',
        name: 'Reportes',
        type: 'sub',
        icon: 'insert_drive_file',
        children: [
            { state: 'reportes-productos', name: 'Productos', type: 'link' },
            { state: 'reportes-venta', name: 'Venta', type: 'link' },
            { state: 'reportes-insumos', name: 'Insumos', type: 'link' }
        ]
    },
    {
        state: 'tiendas',
        name: 'Tiendas',
        type: 'link',
        icon: 'store'
    }
];

const MENUITEMSVENDEDOR = [

    {
        state: 'caja',
        name: 'Caja',
        type: 'sub',
        icon: 'local_atm',
        children: [
            { state: 'abono-caja', name: 'Apertura y abonos', type: 'link' },
            { state: 'cierre-caja', name: 'Cierre de caja', type: 'link' }
        ]
    },
    {
        state: 'venta',
        name: 'Ventas',
        type: 'sub',
        icon: 'attach_money',
        children: [
            { state: 'venta-nueva', name: 'Nueva Venta', type: 'link' },
            { state: 'ventas-recientes', name: 'Ventas Recientes', type: 'link' },
            { state: 'libro-ventas', name: 'Libro de Ventas', type: 'link' },
            { state: 'garantia', name: 'Garantía', type: 'link' }
        ]
    },
    {
        state: 'insumos',
        name: 'Insumos',
        type: 'link',
        icon: 'assignment'
    },
    {
        state: 'reportes',
        name: 'Reportes',
        type: 'sub',
        icon: 'insert_drive_file',
        children: [
            { state: 'reportes-productos', name: 'Productos', type: 'link' }
        ]
    },
];

const MENUITEMSVENDEDORREPO = [

    {
        state: 'caja',
        name: 'Caja',
        type: 'sub',
        icon: 'local_atm',
        children: [
            { state: 'abono-caja', name: 'Apertura y abonos', type: 'link' },
            { state: 'cierre-caja', name: 'Cierre de caja', type: 'link' }
        ]
    },
    {
        state: 'venta',
        name: 'Ventas',
        type: 'sub',
        icon: 'attach_money',
        children: [
            { state: 'venta-nueva', name: 'Nueva Venta', type: 'link' },
            { state: 'ventas-recientes', name: 'Ventas Recientes', type: 'link' },
            { state: 'libro-ventas', name: 'Libro de Ventas', type: 'link' },
            { state: 'garantia', name: 'Garantía', type: 'link' }
        ]
    },
    {
        state: 'insumos',
        name: 'Insumos',
        type: 'link',
        icon: 'assignment'
    },
    {
        state: 'reportes',
        name: 'Reportes',
        type: 'sub',
        icon: 'insert_drive_file',
        children: [
            { state: 'reportes-productos', name: 'Productos', type: 'link' },
            { state: 'reportes-venta', name: 'Venta', type: 'link' },
            { state: 'reportes-insumos', name: 'Insumos', type: 'link' }
        ]
    },
];

const MENUITEMSBODEGA = [
    {
        state: 'inventario',
        name: 'Inventario',
        type: 'sub',
        icon: 'description',
        children: [
            { state: 'marco', name: 'Marcos', type: 'link' },
            { state: 'accesorio', name: 'Accesorios', type: 'link' },
            { state: 'merma', name: 'Merma', type: 'link' }
        ]
    },
    {
        state: 'reportes',
        name: 'Reportes',
        type: 'sub',
        icon: 'insert_drive_file',
        tienda: 'San Pablo',
        children: [
            { state: 'reportes-bodega', name: 'Productos', type: 'link' },
        ]
    },
];

@Injectable()
export class MenuItems {
    getMenuitem(): Menu[] {
        let rol;
        switch (localStorage.getItem('rol')) {
            case 'ADMINISTRADOR':
                rol = MENUITEMS;
                break;

            case 'VENDEDOR':
                rol = MENUITEMSVENDEDOR;
                break;
            
            case 'VENDEDOR_REPO':
                rol = MENUITEMSVENDEDORREPO;
                break;

            case 'BODEGA':
                rol = MENUITEMSBODEGA;
                break;
        }

        return rol;

    }
}
