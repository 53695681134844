import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

/*
  Generated class for the GlobalProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable()
export class GlobalProvider {
    // this is a global variable

    // http://lourdes.phernandez.cl/api/optica/usuario/login
    private host = 'https://optagestion-demo.up.railway.app/api/optica/';
    // private host = 'http://localhost:3000/api/optica/';
  //  private host = '/api/optica/';
      

    public iniciarSesion = this.host + 'usuario/login';
    // empleado
    public getListaEmpleados = this.host + 'usuario/lista';
    public baseEmpleado = this.host + 'usuario/';
    // captador
    public getListaCaptador = this.host+'captador/lista';
    public insertaCaptador = this.host+'captador/';
    public actualizaCaptador = this.host+'captador/'
    public eliminaCaptador = this.host+'captador/';
    //tienda
    public cambioTienda = this.host+'usuario/cambio-tienda/';
    public getListaTiendas = this.host+'tienda/lista';
    public getListaTiendasVendedor = this.host+'tienda/vendedor';
    public insertaTienda = this.host+'tienda/';
    public getTienda = this.host+'tienda/';
    public actualizaTienda = this.host+'tienda/'
    public eliminaTienda = this.host+'tienda/';
    //insumos
    public getListaInsumos = this.host+'insumo/lista';
    public insertaInsumo = this.host+'insumo/';
    public actualizaInsumo = this.host+'insumo/'
    public eliminaInsumo= this.host+'insumo/';
    public reporteInsumo= this.host+'insumo/reporte';
    //marco
    public getListaInventario = this.host+'marco/lista';
    public insertaInventario = this.host+'marco/';
    public actualizaInventario = this.host+'marco/'
    public eliminaInventario = this.host+'marco/';
    public getProductosListaMarca = this.host+'marco/lista-marca';
    //merma
    public getListaMerma = this.host+'merma/lista';
    public insertaMerma = this.host+'merma/';
    //apertura y cierre caja
    public aperturaCaja = this.host+'caja/apertura/';

    //venta buscar
    public buscarVenta = this.host+'venta/';
    public anularVenta = this.host+'venta/anulada/';
    //venta nueva
    public insertaVenta = this.host+'venta';
    public editarVenta = this.host+'venta';
    //garantia
    public insertaGarantia = this.host+'venta/garantia';
    public getListaGarantia = this.host+'venta/garantia/lista';
    //venta anulada
    public getListaVentaAnulada = this.host+'venta/anulada/lista';
    //venta pendiente
    public getListaVentaPendiente = this.host+'venta/pendiente/lista';
    public getListaVentaCliente = this.host+'venta/cliente/';
    public cerrarVenta = this.host+'venta/finalizada/';
    public insertaAbonoVentaPendiente = this.host+'venta/abonos/';
    public actualizaReceta = this.host+'venta/receta';
    //venta finalizada
    public getListaVentaFinalizada = this.host+'venta/finalizada/lista';
    //abono y retiro
    public getAbono = this.host+'venta/abonos/';
    public insertaAbonoCaja = this.host+'caja/abono';
    public retiroCaja = this.host+'caja/retiro';
    public getListaAbonosCaja = this.host+'caja/apertura/lista/';
    public actualizaAbono = this.host+'venta/abono';
    public eliminarAbono = this.host + 'venta/abono/eliminar';
    //dashboard
    public getDashboard = this.host+'reporte/dashboard';
    public getTimeline = this.host + 'timeline/list';

    // reportes
    public getProductosGeneral = this.host+'reporte/productos-general';
    public getProductosSinStock = this.host+'reporte/productos-sin-stock';
    public getIngresoProducto = this.host+'reporte/productos-ingreso/';
    public getVentasAnuladas = this.host+'reporte/ventas-anuladas/';
    public getVentasGeneral = this.host+'reporte/ventas-general/';
    public getTotalVentas = this.host+'reporte/ventas-totales/';
    public getTipoVenta = this.host+'reporte/tipo-venta/';
    public getLenteContacto = this.host+'reporte/lente-contacto/';
    public getComisionesGenerales = this.host+'reporte/comisionesGenerales/';
    public getComisionesPagadas = this.host+'reporte/comisionesPagadas/';
    public getComisionesPendientes = this.host+'reporte/comisionesPendientes/';
    //cierre caja
    public getCierreCaja = this.host+'caja/cierre';	
    public postCierreCaja = this.host+'caja/cierre/crear';
    public abonaComision = this.host+'caja/cierre/abono-comision';
    public getListaCierreCaja = this.host+'caja/cierre/lista/';	
    //cliente
    public getClientes = this.host+'cliente/lista';
    public getClientesByRut = this.host+'cliente/search';
    public baseCliente = this.host+'cliente/';
    //convenio empresas
    public getCompanies = this.host+'company/lista';
    public baseCompany = this.host+'company/';
    //recipe
    public getRecipes = this.host+'recipe/list';
    public baseRecipe = this.host+'recipe/';
    //accesorio
    public getAccesorios = this.host+'accesorio/lista';
    public baseAccesorio = this.host+'accesorio/';
    //configuraciones
    public getConfiguraciones = this.host+'configuracion/lista';
    public resetConfiguraciones = this.host+'configuracion/reset';
    public baseConfiguracion = this.host+'configuracion/';
    //informacion venta
    public info = this.host+'venta/info';

    constructor(public http: HttpClient) {
    }

}
