import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import {
  FormBuilder,
  FormGroup,
  Validators
} from '@angular/forms';
import { LoginService } from '../service/login.service';
import { Login } from '../model/login.model';
import { LocalStorageUtils } from '../utils/clases/localStorageUtil';
import { Constantes } from '../utils/clases/constantesUtil';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  providers: [LoginService]
})
export class LoginComponent implements OnInit {

  public form: FormGroup;

  msg = '';
  login: Login = new Login()

  constructor(
    private _fb: FormBuilder,
    private _loginService: LoginService,
    private _routes: Router,
    private _localStorageUtils: LocalStorageUtils
  ) { }

  ngOnInit() {
    this.msg = '';
    this.formInicial();
  }

  formInicial() {
    this.form = this._fb.group(
      {
        uname: [null,
          Validators.compose([Validators.required])
        ],
        password: [null,
          Validators.compose([Validators.required])
        ]
      }
    );
  }

  get fval() { return this.form.controls; }

  iniciaSesion() {
    this._loginService.llamaLogin(this.fval.uname.value, this.fval.password.value).subscribe(
      res => {
        this.login = res;
        if (!this.login) {
          this.msg = Constantes.ERROR_LOGIN;
        } else {
          this._routes.navigate(['/dashboard']);
          this.localStorageInfo();
        }
      }, (error) => {
        this.msg = Constantes.ERROR_LOGIN;
      }
    );
    this.msg = '';
  }

  localStorageInfo() {

    let insertaItem = new Map(
      [
        [Constantes.TOKEN, this.login.token],
        [Constantes.TIENDA, this.login.user.tienda],
        [Constantes.NOMBRE_TIENDA, this.login.user.nombreTienda],
        [Constantes.ROL, this.login.user.rol],
        [Constantes.NOMBRE, this.login.user.nombre],
        [Constantes.ID, this.login.user._id],
        [Constantes.RUT, this.login.user.rut],
        [Constantes.EMAIL, this.login.user.email],
      ]
    );
    this._localStorageUtils.insertaValorLocalStorage(insertaItem);
  }

  logout() {
    this._routes.navigate(['/login']);
    localStorage.clear();
  }
}
