<!-- ============================================================== -->
<!-- sidebar -->
<!-- ============================================================== -->
<div class="user-profile" style="background: url(assets/images/logo.png) no-repeat; background-size: contain; margin-top: 16px;">
    <br>
    <br>
    <br>
    <br>
    <br>


</div>

<mat-nav-list appAccordion>
    <mat-list-item appAccordionLink *ngFor="let menuitem of menuItems.getMenuitem()" routerLinkActive="selected"
        group="{{menuitem.state}}" (click)="scrollToTop()">
        <a appAccordionToggle [routerLink]="['/', menuitem.state]" *ngIf="menuitem.type === 'link'">
            <mat-icon style="color: #1E88E5">{{ menuitem.icon }}</mat-icon>
            <span>{{ menuitem.name }}</span>
            <span fxFlex></span>
            <span class="label label-{{ badge.type }}" *ngFor="let badge of menuitem.badge">{{ badge.value }}</span>
        </a>

        <a appAccordionToggle href="{{menuitem.state}}" *ngIf="menuitem.type === 'extLink'">
            <mat-icon style="color: #1E88E5">{{ menuitem.icon }}</mat-icon>
            <span>{{ menuitem.name }}</span>
            <span fxFlex></span>
            <span class="label label-{{ badge.type }}" *ngFor="let badge of menuitem.badge">{{ badge.value }}</span>
        </a>
        <a appAccordionToggle href="{{menuitem.state}}" target="_blank" *ngIf="menuitem.type === 'extTabLink'">
            <mat-icon style="color: #1E88E5">{{ menuitem.icon }}</mat-icon>
            <span>{{ menuitem.name}}</span>
            <span fxFlex></span>
            <span class="label label-{{ badge.type }}" *ngFor="let badge of menuitem.badge">{{ badge.value }}</span>
        </a>

        <a appAccordionToggle href="javascript:;" *ngIf="menuitem.type === 'sub' && menuitem.tienda === undefined">
            <mat-icon style="color: #1E88E5">{{ menuitem.icon }}</mat-icon>
            <span>{{ menuitem.name }}</span>
            <span fxFlex></span>
            <span class="label label-{{ badge.type }}" *ngFor="let badge of menuitem.badge">{{ badge.value }}</span>
            <mat-icon class="dd-icon">keyboard_arrow_down</mat-icon>
        </a>
        <a appAccordionToggle href="javascript:;" *ngIf="menuitem.type === 'sub' && tienda === 'San Pablo' && rol === 'BODEGA' && menuitem.tienda !== undefined">
            <mat-icon style="color: #1E88E5">{{ menuitem.icon }}</mat-icon>
            <span>{{ menuitem.name }}</span>
            <span fxFlex></span>
            <span class="label label-{{ badge.type }}" *ngFor="let badge of menuitem.badge">{{ badge.value }}</span>
            <mat-icon class="dd-icon">keyboard_arrow_down</mat-icon>
        </a>
        <mat-nav-list class="sub-item" *ngIf="menuitem.type === 'sub'">
            <mat-list-item *ngFor="let childitem of menuitem.children; let j=index; let i = childitem"
                routerLinkActive="selected">
                <a [routerLink]="['/', menuitem.state, childitem.state ]" *ngIf="childitem.type === 'link'"
                    class="relative" routerLinkActive="selected" (click)="itemSelect[i]=j">{{ childitem.name}}</a>
            </mat-list-item>
            <mat-list-item *ngFor="let childitem of menuitem.children; let j=index; let i=childitem">
                <a class="" href="javascript: void(0);" *ngIf="childitem.type === 'subchild'" (click)="itemSelect[i]=j"
                    [ngClass]="j==itemSelect[i]? 'selected' : ''">
                    <span>{{ childitem.name }}</span>
                    <span fxFlex></span>
                    <mat-icon class="dd-icon">keyboard_arrow_down</mat-icon>
                </a>
                <mat-nav-list class="child-sub-item" *ngIf="childitem.type === 'subchild'">
                    <mat-list-item *ngFor="let child of childitem.subchildren" routerLinkActive="selected">
                        <a [routerLink]="['/', menuitem.state, childitem.state, child.state ]"
                            routerLinkActive="selected">{{child.name}}</a>
                    </mat-list-item>
                </mat-nav-list>
            </mat-list-item>

        </mat-nav-list>

        <div class="saperator text-muted" *ngIf="menuitem.type === 'saperator'">
            <span>{{ menuitem.name }}</span>
        </div>
    </mat-list-item>
</mat-nav-list>
<br>
<br>
<br>